



































































































































































































import { Component, Vue, Emit, Watch } from 'vue-property-decorator'
import { StudentController, DiaryController } from '@/services/request.service'
import InjuredMark from '@/components/InjuredMark.vue'
import moment from 'moment'
import { i18n } from '@/i18n/i18n'
import filterGroup from '@/components/filterGroup'
import { toPage } from '@/utils/utils'
import flexTooltip from '@/components/FlexTooltip.vue'
import { createPagination } from '@/constant/constant'

@Component({
  components: {
    InjuredMark,
    filterGroup,
    flexTooltip,
  },
})
export default class Parent extends Vue {
  private visible: Array<any> = []
  private moment = moment
  private studentInfo: any = {}
  private childTypes: Array<any> = []
  private isNurse: boolean = false
  private isAttendError: boolean = false
  private students: Array<any> = []
  private parents: Array<any> = []
  private teachers: Array<any> = []
  private modalInfo: any = {}
  private modalVisible: boolean = false
  private loading: boolean = true
  private dataSource: Array<any> = []
  private diaryTypes: Array<any> = []
  private pagination: any = createPagination({
    showTotal: total => i18n.t('common.pageTotal', { total }),
  })
  private filter: any = {
    primaryTypeId: 0,
    diaryEntryTypeId: 0,
    createTime: null,
  }

  private get columns(): Array<any> {
    return [
      {
        key: 'primaryType',
        title: this.$t('diary.diaryType'),
        scopedSlots: { customRender: 'primaryType' },
      },
      {
        key: 'diaryEntryType',
        title: this.$t('diary.childType'),
        scopedSlots: { customRender: 'diaryEntryType' },
      },
      {
        key: 'description',
        title: this.$t('diary.introduction'),
        scopedSlots: { customRender: 'describe' },
      },
      {
        dataIndex: 'points',
        key: 'points',
        title: this.$t('diary.points'),
        scopedSlots: { customRender: 'points' },
      },
      {
        dataIndex: 'displayName',
        title: this.$t('common.creator'),
        ellipsis: true,
        scopedSlots: { customRender: 'displayName' },
      },
      {
        key: 'recordTime',
        title: this.$t('diary.createTime'),
        scopedSlots: { customRender: 'recordTime' },
      },
    ]
  }

  private get locale(): string {
    return this.$store.state.locale
  }

  private created(): void {
    this.$nextTick(() => {
      this.getPrimaryTypes()
      this.getData()
    })
  }

  private reset(): void {
    this.filter = {
      primaryTypeId: 0,
      diaryEntryTypeId: 0,
      createTime: null,
    }
    this.$set(this.pagination, 'current', 1)
    this.refresh()
  }

  private changeTime(date, dateString): void {
    if (date.length === 0) {
      this.filter.createTime = null
    }
    this.getData()
  }

  private changeDiaryType(): void {
    this.filter.diaryEntryTypeId = 0
    const primaryTypeId = this.filter.primaryTypeId
    DiaryController.getDiaryEntryTypes(primaryTypeId)
      .then(res => {
        this.childTypes = res.data
      })
      .catch(err => {
        console.error(err)
      })
      .finally(() => this.getData())
  }

  private closeModal(): void {
    this.modalVisible = false
  }

  private viewDetail(diaryEntryId): void {
    DiaryController.getDiaryEntry(diaryEntryId)
      .then(res => {
        this.modalInfo = res.data
        this.visible = this.modalInfo.injureParts.map(item => false)
        this.modalInfo.studentsName = this.modalInfo.students.map(item => item.name).join('、')
        if (res.data.primaryTypeId === 7) {
          this.isAttendError = false
        } else {
          this.isAttendError = true
        }
        if (res.data.primaryTypeId === 4) {
          this.isNurse = true
        } else {
          this.isNurse = false
        }
        this.modalVisible = true
      })
      .catch(err => {
        console.error(err)
      })
  }

  private getStudentDiaryInfo(): void {
    DiaryController.getStudentDiaryInfo()
      .then(res => {
        this.studentInfo = res.data
      })
      .catch(err => {
        console.error(err)
      })
  }

  private getData(page = { current: 1, pageSize: this.pagination.defaultPageSize }): void {
    this.getStudentDiaryInfo()
    const request = {
      pageCurrent: page.current,
      pageSize: page.pageSize,
      primaryTypeId: this.filter.primaryTypeId || undefined,
      diaryEntryTypeId: this.filter.diaryEntryTypeId || undefined,
      start: this.filter.createTime ? moment(this.filter.createTime[0]).valueOf() : undefined,
      end: this.filter.createTime ? moment(this.filter.createTime[1]).valueOf() : undefined,
    }
    DiaryController.getDiaryEntrySummary(
      request.pageCurrent,
      request.pageSize,
      request.primaryTypeId,
      request.diaryEntryTypeId,
      request.start,
      request.end
    )
      .then(res => {
        this.dataSource = res.data.items
        this.pagination.total = res.data.totalItem
        this.pagination.current = page.current
        toPage(page, this.dataSource, page => {
          this.pagination.current = page
          this.refresh()
        })
      })
      .catch(err => {
        console.error(err)
      })
      .finally(() => {
        this.loading = false
      })
  }

  private handleTableChange(pagination): void {
    this.$set(this.pagination, 'current', pagination.current)
    this.$set(this.pagination, 'pageSize', pagination.pageSize)
    this.getData(pagination)
  }

  private getPrimaryTypes(): void {
    DiaryController.getPrimaryTypes()
      .then(res => {
        this.diaryTypes = res.data
      })
      .catch(err => {
        console.error(err)
      })
  }

  private refresh(): void {
    this.getData(this.pagination)
  }
}
